<template>
	<b-form @submit.prevent="onSubmit()" @reset.prevent="onCancel()">
		<!-- Prescription Name -->
		<b-form-group label="Prescription Name*" label-for="name" :invalid-feedback="getErrorMessage(errors, 'name')" :state="getErrorState(errors, 'name')">
			<b-form-input :state="getErrorState(errors, 'name')" type="text" id="name" v-model="form.name" placeholder="Prescription Name" trim />
		</b-form-group>

		<!-- Image Uploader -->
		<b-form-group label="Upload Prescription*" label-for="prescriptionImage" :invalid-feedback="getErrorMessage(errors, 'prescriptionImage')" :state="getErrorState(errors, 'prescriptionImage')">
			<b-form-file v-model="form.prescriptionImage" :state="getErrorState(errors, 'prescriptionImage')" accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." no-drop />
		</b-form-group>

		<div class="text-center">
			<b-button type="submit" class="mr-2 my-2" variant="primary" :disabled="busyState">
				Save
			</b-button>
			<b-button type="reset" :disabled="busyState"> Cancel </b-button>
		</div>
	</b-form>
</template>

<script>
import { mapActions } from "vuex";
import { toFormData } from "../../functions/utils";

export default {
	data() {
		return {
			busyState: false,
			form: {
				name: "",
				prescriptionImage: null,
			},
			errors: null,
		};
	},
	methods: {
		...mapActions({
			addPrescription: "user/userPrescriptions/addPrescription",
		}),
		onSubmit() {
			const prescriptionForm = toFormData(this.form);
			const file = this.file;
			if (file) prescriptionForm.append("prescriptionImage", file, file.name);
			this.busyState = true;
			this.addPrescription(prescriptionForm)
				.then((data) => {
					this.$emit("success", data);
				})
				.catch(({ data }) => (this.errors = data.errors))
				.finally(() => (this.busyState = false));
		},
		onCancel() {
			this.$emit("cancel");
		},
	},
};
</script>
