<template>
	<b-row>
		<b-col offset-sm="1" sm="10" offset-lg="2" lg="8">
			<b-card>
				<!-- Title -->
				<b-card-title class="text-primary">
					Add Prescription
				</b-card-title>

				<user-prescription-form-component @success="onSuccess()" @cancel="onCancel()" />
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import UserPrescriptionFormComponent from "../../../../components/prescriptions/UserPrescriptionFormComponent";
export default {
	components: { UserPrescriptionFormComponent },
	methods: {
		onSuccess() {
			this.$router.replace({ name: "UserPrescriptions" });
		},
		onCancel() {
			this.$router.replace({ name: "UserPrescriptions" });
		},
	},
};
</script>

<style></style>
